import ApiService from "@/core/services/api.service";

// action types
export const FETCH_USERS = "fetchUsers";
export const FETCH_CITIES = "fetchCities";
export const FETCH_COUNTIES = "fetchCounties";

// mutation types
export const SET_USERS = "setUsers";
export const SET_COUNTIES = "setCounties";
export const SET_CITIES = "setCities";

const state = {
  users: [],
  counties: [],
  cities: []
};

const getters = {
  users(state) {
    return state.users;
  },
  counties(state) {
    return state.counties;
  },
  cities(state) {
    return state.cities;
  }
};

const actions = {
  [FETCH_USERS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("/api/users/all")
        .then(({ data }) => {
          context.commit(SET_USERS, data);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [FETCH_COUNTIES](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("/api/konnektor/counties")
        .then(({ data }) => {
          context.commit(SET_COUNTIES, data.data);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [FETCH_CITIES](context, countyCode) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("/api/konnektor/cities/" + countyCode)
        .then(({ data }) => {
          context.commit(SET_CITIES, data.data);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_USERS](state, users) {
    state.users = users;
  },
  [SET_CITIES](state, cities) {
    state.cities = cities;
  },
  [SET_COUNTIES](state, counties) {
    state.counties = counties;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
