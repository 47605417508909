<template>
  <v-col>
    <v-row>
      <v-col cols="12" lg="6" sm="12">
        <v-row>
          <v-col>
            <TotalStats title="Comenzi" label1="Total" label2="Incasari" :value1="this.statistics.totals.orders.policies" :value2="this.statistics.totals.orders.revenue"></TotalStats>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <TotalStats title="RCA" label1="Total" label2="Incasari" :value1="this.statistics.totals.rca.policies" :value2="this.statistics.totals.rca.revenue"></TotalStats>
          </v-col>
          <v-col>
            <TotalStats title="Travel" label1="Total" label2="Incasari" :value1="this.statistics.totals.travel.policies" :value2="this.statistics.totals.travel.revenue"></TotalStats>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="6" sm="12">
        <VariationStats
            style="height: 100%"
            title="Utilizatori"
            label1="Total"
            label2="Variatie"
            :value1="this.statistics.variation.users.total"
            :value2="this.statistics.variation.users.thisMonth"
            :series="this.statistics.variation.users.series">
        </VariationStats>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="6" sm="12">
        <VariationStats
            title="Cotatii RCA"
            label1="Total"
            label2="Variatie"
            :value1="this.statistics.variation.quotations.rca.total"
            :value2="this.statistics.variation.quotations.rca.thisMonth"
            :series="this.statistics.variation.quotations.rca.series">
        </VariationStats>
      </v-col>
      <v-col cols="12" lg="6" sm="12">
        <VariationStats
            title="Cotatii Travel"
            label1="Total"
            label2="Variatie"
            :value1="this.statistics.variation.quotations.travel.total"
            :value2="this.statistics.variation.quotations.travel.thisMonth"
            :series="this.statistics.variation.quotations.travel.series">
        </VariationStats>
      </v-col>
    </v-row>
  </v-col>

</template>

<script>
import TotalStats from "@/view/content/widgets/custom/TotalStats.vue";
import VariationStats from "@/view/content/widgets/custom/VariationStats.vue";
import {
  FETCH_POLICY_TOTAL,
  FETCH_QUOTATION_VARIATION,
  FETCH_USER_VARIATION
} from "@/core/services/store/statistics.module";
import {mapGetters} from "vuex";

export default {
  name: "Statistics",
  components: {
    TotalStats,
    VariationStats
  },
  computed: {
    ...mapGetters(["stats"]),
  },
  data() {
    return {
      statistics: {
        totals: {
          orders: {
            total: 0,
            revenue: 0
          },
          rca: {
            total: 0,
            revenue: 0
          },
          travel: {
            total: 0,
            revenue: 0
          },
        },
        variation: {
          users: {
            total: 0,
            thisMonth: +0,
            series: [
              {
                name: "Total",
                data: [],
              },
            ]
          },
          quotations: {
            rca: {
              total: 0,
              thisMonth: +0,
              series: [
                {
                  name: "Total",
                  data: [],
                },
              ]
            },
            travel: {
              total: 0,
              thisMonth: +0,
              series: [
                {
                  name: "Total",
                  data: [],
                },
              ]
            }
          }
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Promise.all([
        this.$store.dispatch(FETCH_USER_VARIATION),
        this.$store.dispatch(FETCH_QUOTATION_VARIATION, 'rca'),
        this.$store.dispatch(FETCH_QUOTATION_VARIATION, 'travel'),
        this.$store.dispatch(FETCH_POLICY_TOTAL, 'rca'),
        this.$store.dispatch(FETCH_POLICY_TOTAL, 'travel')
      ])
      .then(() => {
        this.statistics = this.stats;
      })
    }
  }
};
</script>
