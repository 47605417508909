<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
        :class="{
        'login-signin-on': this.state == 'signin'
      }"
        id="kt_login"
    >
      <div
          class="
          login-aside
          d-flex
          flex-row-auto
          bgi-size-cover bgi-no-repeat
          p-10 p-lg-15
        "
          :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside content -->
          <div
              class="flex-column-fluid d-flex flex-column justify-content-center"
          >
            <h3 class="font-size-h1 mb-5 text-white">Bun venit!</h3>
            <p class="font-weight-lighter text-white opacity-80">
              Introduceti email-ul si parola pentru a accesa platforma de
              administrare MySafety.
            </p>
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div
              class="
              d-none
              flex-column-auto
              d-lg-flex
              justify-content-between
              mt-15
            "
          >
            <div class="opacity-70 font-weight-bold text-white">
              © {{ new Date().getFullYear() }} Life is Hard - Work Soft
            </div>
            <div class="d-flex">
              <a href="#" class="text-white">Privacy</a>
              <a href="#" class="text-white ml-10">Legal</a>
              <a href="#" class="text-white ml-10">Contact</a>
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
          class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
                @submit.stop.prevent="onSubmitLogin"
            >
              <div class="pb-13 pt-lg-0 pt-5 text-center">
                <a href="#" class="flex-column-auto w-100">
                  <img src="media/logos/logo.png" class="h-25"/>
                </a>
                <v-alert
                    class="mt-5"
                    v-if="error"
                    dismissible
                    dense
                    outlined
                    type="error"
                >
                  {{ error }}
                </v-alert>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                >Email</label
                >
                <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <input
                      class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                      type="text"
                      name="email"
                      ref="email"
                      v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                  >Password</label
                  >
                </div>
                <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                >
                  <input
                      class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                      type="password"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5 d-flex justify-content-end">
                <button
                    ref="kt_login_signin_submit"
                    class="btn btn-secondary text-white font-weight-bolder font-size-h6 px-15 py-4 my-3 ml-3"
                    :class="requestInProgress ? 'spinner spinner-light spinner-right' : ''"
                >
                  Intra in cont
                </button>
<!--                <v-progress-circular-->
<!--                    indeterminate-->
<!--                    v-if="requestInProgress"-->
<!--                    color="primary"-->
<!--                ></v-progress-circular>-->
<!--                <v-btn-->
<!--                    ref="kt_login_signin_submit"-->
<!--                    :loading="requestInProgress"-->
<!--                    :disabled="requestInProgress"-->
<!--                    x-large-->
<!--                    color="secondary"-->
<!--                >Intra in cont</v-btn>-->
              </div>
            </form>
          </div>
        </div>
        <!--begin::Content footer-->
        <div
            class="
            d-flex
            justify-content-lg-start justify-content-center
            align-items-end
            py-7 py-lg-0
          "
        >
          <!-- <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Termeni si Conditii</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Politica de Confidentialitate</a
          > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import {mapGetters, mapState} from "vuex";
import {LOGIN, LOGOUT} from "@/core/services/store/auth.module";
import _ from "lodash";

export default {
  name: "login-1",
  data() {
    return {
      error: null,
      state: "signin",
      requestInProgress: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/bg/demo-7.jpg";
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Va rugam, introduceti email-ul"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Va rugam, introduceti parola"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin: _.debounce(function () {
      this.requestInProgress = true;
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var email = this.form.email;
        var password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        // const submitButton = this.$refs["kt_login_signin_submit"];
        // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        this.$store
            .dispatch(LOGIN, {email, password})
            .then(() => {
              this.$router.push({name: "statistics"});
            })
            .catch(err => {
              this.showError(err);
              this.requestInProgress = false;
            });

        // submitButton.classList.remove(
        //     "spinner",
        //     "spinner-light",
        //     "spinner-right"
        // );
      });

      this.fv.on("core.form.invalid", () => {
        this.requestInProgress = false;
      });
    }, 1000),

    showError(error) {
      if (error.code === "auth/wrong-password") this.error = "Parola invalida.";
      else if (error.code === "auth/user-not-found")
        this.error = "Nu exista un cont cu acest email";
      else if (error.code === "auth/invalid-email")
        this.error = "E-mail invalid";
      else this.error = error;
    }
  }
};
</script>
