import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/core/services/store";
// import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import FirebaseService from "@/core/services/firebase.service";
import "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/view/pages/auth/Auth")
  },
  {
    path: "/",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        name: "statistics",
        path: "/statistics",
        meta: { requiresAuth: true },
        component: () => import("@/view/pages/admin/Statistics")
      },
      {
        name: "users",
        path: "/users",
        meta: { requiresAuth: true },
        component: () => import("@/view/pages/admin/Users")
      },
      {
        name: "orders",
        path: "/orders",
        meta: { requiresAuth: true },
        component: () => import("@/view/pages/admin/Orders")
      },
      {
        name: "campaigns",
        path: "/campaigns",
        meta: { requiresAuth: true },
        component: () => import("@/view/pages/admin/Campaigns")
      },
      {
        path: "*",
        redirect: '/statistics'
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = FirebaseService.getAuth().currentUser;

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
