<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: User Bar -->
    <v-spacer />
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  width: 100%;
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import { mapGetters } from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      route: "",
    };
  },
  components: {
    KTQuickUser,
  },
  methods: {
    initialize() {
      if (this.currentUser.type !== "superadmin") return;

      // this.$store.dispatch(FETCH_CITIES);
    },
    setCity(cityKey) {
      this.$store.commit(SET_CURRENT_CITY, cityKey);
    },
    isAdminPage() {
      let route = this.$router.currentRoute;
      console.log(route);
    },
  },
  computed: {
    ...mapGetters(["currentUser", "cities", "isSuperadmin"]),
    shouldShowCityPicker() {
      if (this.route === "cities") return false;
      if (this.route === "users") return false;
      if (!this.route) return false;

      return true;
    },
  },
  watch: {
    $route(to, from) {
      this.route = to.name;
    },
  },
  created() {
    this.initialize();
  },
};
</script>
