<template>
  <div>
    <v-card class="elevation-1 my-4">
      <v-card-title>Filtrare</v-card-title>
      <v-card-text>
        <v-row>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label for="dp_start">De la</label>
            <b-form-datepicker
                id="dp_start"
                v-model="filters.startDate"
                class="mb-2"
                locale="ro-RO"
                reset-button
                label-no-date-selected="alegeti...">
            </b-form-datepicker>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label for="dp_end">Pana la</label>
            <b-form-datepicker
                id="dp_end"
                v-model="filters.endDate"
                class="mb-2"
                locale="ro-RO"
                reset-button
                label-no-date-selected="alegeti...">
            </b-form-datepicker>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label>Comenzi</label>
            <b-select
                id="select_type"
                v-model="filters.orders"
                :options="orderOptions"
                class="mb-2">
            </b-select>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label>Judet</label>
            <b-select
                id="select_type"
                v-model="filters.county"
                :options="apiCounties"
                class="mb-2">
            </b-select>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label>Oras</label>
            <b-select
                id="select_type"
                v-model="filters.city"
                :options="apiCities"
                class="mb-2">
            </b-select>
          </v-col>

          <v-col lg="4" md="6" sm="12" cols="12">
            <label></label>
            <div>
              <download-csv
                  :data="exportableItems"
                  :fields="[
                'cnp',
                'first_name',
                'last_name',
                'email',
                'address',
                'county_name',
                'city_name'
              ]">
                <v-btn class="my-2 w-100" color="warning" :disabled="!exportableItems.length"> Export CSV
                  {{ exportableItems.length ? `(${exportableItems.length})` : '' }}
                </v-btn>
              </download-csv>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-alert dark color="error" v-if="error" class="text-center py-1">
      A intervenit o eroare la obtinerea comenzilor.
      <v-btn icon @click="initialize"><v-icon>mdi-refresh</v-icon></v-btn>
    </v-alert>

    <v-data-table
        :headers="headers"
        :items="filteredUsers"
        :loading="!dataLoaded"
        show-expand
        single-expand
        loading-text="Se incarca..."
        sort-by="name"
        class="elevation-1"
        v-model="exportableItems"
        @toggle-select-all="selectAll"
        show-select
        :footer-props="{ itemsPerPageText: 'Linii per pagina ' }"
    >
      <template v-slot:item.logo="{ item }">
        <img
            :src="item.logo"
            class="h-50 align-self-center"
            alt=""
            onerror="this.onerror=null; this.src='media/svg/icons/Home/Building.svg'"
        />
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Utilizatori</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cauta CNP, nume, email, nr. de inmatriculare sau VIN"
              single-line
              dense
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }"></template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} din {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset</v-btn>
      </template>
      <template v-slot:item.first_name="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:item.address="{ item }">
        {{ formatAddress(item) }}
      </template>
      <template v-slot:item.registeredOn="{ item }">
        {{ formatDate(item.registeredOn) }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="p-1">
          <div class="card m-2">
            <v-subheader>Masini</v-subheader>
            <v-simple-table dense v-if="item.cars.length">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Nr. de inmatriculare</th>
                  <th class="text-left">Serie sasiu</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="car in item.cars" :key="item.registration_nr">
                  <td>{{ car.registration_nr }}</td>
                  <td>{{ car.vin }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <span v-else class="p-3"
            >Utilizatorul nu are masini inregistrare in aplicatie.</span
            >
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  FETCH_USERS,
  FETCH_CITIES,
  FETCH_COUNTIES,
} from "@/core/services/store/user.module";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "Users",
  data() {
    return {
      dialog: false,
      dataLoaded: false,
      search: "",
      headers: [
        {text: "CNP", value: "cnp"},
        {text: "Nume", value: "first_name"},
        {text: "Email", value: "email"},
        {text: "Adresa", value: "address"},
        {text: "Judet", value: "county_name"},
        {text: "Oras", value: "city_name"},
        {text: "Inregistrat la", value: "registeredOn"},
      ],
      filters: {
        city: "Toate",
        county: "Toate",
        orders: "Toti",
        startDate: null,
        endDate: null,
      },
      orderOptions: ["Toti", "Cu comenzi", "Fara comenzi"],
      exportableItems: [],
      error: false
    };
  },
  computed: {
    ...mapGetters(["users", "cities", "counties", "currentUser"]),
    filteredUsers() {
      let filteredUsers = [];
      for (let user of this.users) {
        let valid = true;

        if (
            this.filters.city &&
            this.filters.city !== "Toate" &&
            user.city_name !== this.filters.city.name
        )
          valid = false;

        if (
            this.filters.county &&
            this.filters.county !== "Toate" &&
            user.county_name !== this.filters.county.name
        )
          valid = false;

        if (this.filters.orders && this.filters.orders !== "Toti") {
          if (this.filters.orders === "Cu comenzi") {
            if ((!user.rcas) && (!user.travels)) {
              valid = false;
            }
          } else {
            if ((user.rcas) || (user.travels)) {
              valid = false;
            }
          }
        }

        if (this.filters.startDate) {
          if (!user.registeredOn || moment(this.filters.startDate).isAfter(moment(user.registeredOn))) valid = false;
        }
        if (this.filters.endDate) {
          if (!user.registeredOn || moment(this.filters.endDate).isBefore(moment(user.registeredOn))) valid = false;
        }

        if (this.search && (
            (!user.first_name || !user.first_name.toLowerCase().includes(this.search)) &&
            (!user.last_name || !user.last_name.toLowerCase().includes(this.search)) &&
            (!user.cnp || !user.cnp.toLowerCase().includes(this.search)) &&
            (!user.email || !user.email.toLowerCase().includes(this.search)))) {

          if (!user.cars) {
            valid = false;
          }
          else {
            let hasCar = false;
            for (let car of user.cars) {
              if((car.vin && car.vin.toLowerCase().includes(this.search.toLowerCase()))
                  || (car.registration_nr && car.registration_nr.toLowerCase().includes(this.search.toLowerCase()))) {
                hasCar = true;
                break;
              }
            }
            if (!hasCar) valid = false;
          }
        }


        if (valid) filteredUsers.push(user);
      }

      return filteredUsers;
    },
    apiCounties() {
      let c = ["Toate"];
      for (let county of this.counties) {
        c.push({text: county.name, value: county})
      }
      return c;
    },
    apiCities() {
      let c = ["Toate"];
      for (let city of this.cities) {
        c.push({text: city.name, value: city})
      }
      return c;
    },
    countyFilter() {
      return this.filters.county;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    countyFilter() {
      console.log("FILTERING");
      if (this.filters.county) {
        this.filters.city = "Toate";
        this.$store.dispatch(FETCH_CITIES, this.filters.county.code);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.dataLoaded = false;
      this.error = false;

      Promise.all([
        this.$store.dispatch(FETCH_USERS),
        this.$store.dispatch(FETCH_COUNTIES),
      ]).then(() => {
        this.dataLoaded = true;
      })
      .catch(err => {
        this.dataLoaded = true;
        this.error = true;
      });
    },
    formatAddress(item) {
      let fullAddress = "";

      if (item.street) fullAddress += `${item.street} ${item.nr}`;
      if (item.bloc) fullAddress += ` bloc ${item.bloc}`;
      if (item.scara) fullAddress += ` scara ${item.scara}`;
      if (item.etaj) fullAddress += ` etaj ${item.etaj}`;
      if (item.apartament) fullAddress += ` apartament ${item.ap}`;

      return fullAddress;
    },
    selectAll(event) {
      console.log(event.value);
      if (event.value) {
        this.exportableItems = this.filteredUsers;
      } else {
        this.exportableItems = [];
      }
    },
    clearFilters() {
      this.filters = {
        city: "Toate",
        county: "Toate",
        orders: false,
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return '-'
      return moment(timestamp).format('DD/MM/YYYY');
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
