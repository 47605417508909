import ApiService from "@/core/services/api.service";

export const FETCH_CAMPAIGNS = "fetchCampaigns";
export const SET_CAMPAIGNS = "fetchCampaigns";
export const EDIT_CAMPAIGN = "editCampaign";
export const ADD_CAMPAIGN = "addCampaign";
export const GENERATE_LINK = "generateLink";

const state = {
    campaigns: []
}

const getters = {
    campaigns(state) {
        return state.campaigns;
    }
}

const actions = {
    [FETCH_CAMPAIGNS](context) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get("/api/campaigns")
                .then(({ data }) => {
                    context.commit(SET_CAMPAIGNS, data);
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    },
    [ADD_CAMPAIGN](context, campaign) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post("api/campaigns", campaign)
                .then(({ data }) => {
                    context.dispatch(FETCH_CAMPAIGNS)
                        .then(() => resolve());
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    },
    [EDIT_CAMPAIGN](context, campaign) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(`api/campaigns/${campaign.id}`, campaign)
                .then(() => {
                    context.dispatch(FETCH_CAMPAIGNS)
                        .then(() => resolve());
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    },
    [GENERATE_LINK](context, campaign) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get(`api/campaigns/dynamicLink?id=${campaign.id}&name=${campaign.name}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    }
}

const mutations = {
    [SET_CAMPAIGNS](state, data) {
        state.campaigns = data;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
