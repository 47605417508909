<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="campaigns"
        :loading="!dataLoaded"
        loading-text="Se incarca..."
        sort-by="name"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Linii per pagina ' }"
    >
      <template v-slot:item.logo="{ item }">
        <img
            :src="item.logo"
            class="h-50 align-self-center"
            alt=""
            onerror="this.onerror=null; this.src='media/svg/icons/Home/Building.svg'"
        />
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title style="min-width: 65px">Campanii</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
              class="mx-2"
              v-model="search"
              append-icon="mdi-magnify"
              label="Cauta"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                Campanie noua
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                        label="Nume"
                        outlined
                        v-model="editedItem.name">
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                        label="Dynamic Link"
                        outlined
                        required
                        v-model="editedItem.dynamic_link"
                        hint="Apasati butonul pentru a genera automat un Dynamic Link">
                      <template #append>
                        <v-btn
                            @click="generateLink"
                            color="primary"
                            text
                            :loading="generatingLink"
                            :disabled="!editedItem.name"
                            class="mt-n2"
                        >
                          <v-icon dark>mdi-cached</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>

                  </v-row>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-date-picker
                          v-model="dates"
                          range
                          form
                      ></v-date-picker>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-text-field
                          v-model="dateRangeText"
                          label="Perioada"
                          disabled
                          required
                          prepend-icon="mdi-calendar"
                          readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Anuleaza
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                    @click="save"
                >
                  Salveaza
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="statistics"
              max-width="800px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ selectedCampaign.name ? selectedCampaign.name : 'Statistici' }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeStatistics">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!--                    <v-col>-->
                    <!--                      <v-text-field dense outlined label="Data inceput" v-model="exportStartDate"></v-text-field>-->
                    <!--                    </v-col>-->
                    <v-col>
                      <label for="dp_start">Data inceput</label>
                      <b-form-datepicker
                          id="dp_start"
                          v-model="exportStartDate"
                          :min="calendarMin"
                          :max="exportEndDate ? exportEndDate : calendarMax"
                          class="mb-2"
                          locale="ro-RO"
                          reset-button
                          label-no-date-selected="alegeti...">
                      </b-form-datepicker>
                    </v-col>
                    <!--                    <v-col>-->
                    <!--                      <v-text-field dense outlined label="Data final" v-model="exportEndDate"></v-text-field>-->
                    <!--                    </v-col>-->
                    <v-col>
                      <label for="dp_end">Data final</label>
                      <b-form-datepicker
                          id="dp_end"
                          v-model="exportEndDate"
                          :min="exportStartDate ? exportStartDate : calendarMin"
                          :max="calendarMax"
                          class="mb-2"
                          locale="ro-RO"
                          reset-button
                          label-no-date-selected="alegeti...">
                      </b-form-datepicker>
                    </v-col>
                    <v-col>
                      <br/>
                      <download-csv :data="exportableStatistics">
                        <v-btn color="primary" class="w-100 mt-2">Export
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </download-csv>
                    </v-col>
                  </v-row>
                  <apexchart
                      class="m-5"
                      :options="chartOptions"
                      :series="campaignStatistics"
                      type="area"
                  ></apexchart>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.start_date_millis="{ item }">
        {{ formatDate(item.start_date_millis) }}
      </template>
      <template v-slot:item.end_date_millis="{ item }">
        {{ formatDate(item.end_date_millis) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            title="Editare"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            class="mr-2"
            @click="showStatistics(item)"
            title="Statistici"
        >
          mdi-chart-bar
        </v-icon>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} din {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  FETCH_CAMPAIGNS,
  ADD_CAMPAIGN,
  EDIT_CAMPAIGN,
  GENERATE_LINK,
} from "@/core/services/store/campaigns.module";
import {
  FETCH_CAMPAIGN_STATISTICS
} from "@/core/services/store/statistics.module";

import {mapGetters} from "vuex";
import moment from "moment";
import short from "short-uuid";

export default {
  name: "Campaigns.vue",
  data() {
    return {
      dialog: false,
      statistics: false,
      search: '',
      dataLoaded: false,
      generatingLink: false,
      statisticsLoading: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        start_date_millis: 0,
        end_date_millis: 0,
        dynamic_link: '',
        registered_accounts: 0
      },
      defaultItem: {
        name: '',
        start_date_millis: 0,
        end_date_millis: 0,
        dynamic_link: '',
        registered_accounts: 0
      },
      selectedCampaign: {},
      exportStartDate: null,
      exportEndDate: null,
      calendarMin: null,
      calendarMax: null,
      dates: [],
      headers: [
        {text: "Nume", value: "name"},
        {text: "Conturi inregistrate", value: "registered_accounts"},
        {text: "Data incepere", value: "start_date_millis"},
        {text: "Data incheiere", value: "end_date_millis"},
        {text: "Actiuni", value: "actions"},
      ],
    }
  },
  computed: {
    ...mapGetters(['campaigns', 'campaignStatistics', 'layoutConfig']),
    formTitle() {
      return this.editedIndex === -1 ? 'Adauga campanie' : 'Editeaza campanie'
    },
    dateRangeText() {
      let datesFormatted = [];
      for (let date of this.dates) {
        datesFormatted.push(moment(date).format('DD/MM/YYYY'));
      }
      return datesFormatted.join(' ~ ')
    },
    valid() {
      return this.editedItem && this.editedItem.name
          && this.editedItem.dynamic_link
          && this.dates.length === 2
    },
    chartOptions() {
      return {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [this.layoutConfig("colors.theme.base.info")],
        },
        xaxis: {
          // categories: ["23 luni","22 luni","21 luni","20 luni","19 luni","18 luni","17 luni","16 luni","15 luni","14 luni","13 luni", "12 luni", "11 luni","10 luni","9 luni","8 luni","7 luni","6 luni","5 luni","4 luni","3 luni","2 luni","o luna", "luna aceasta"],
          categories: this.xLabels,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family"),
            },
          },
          crosshairs: {
            show: false,
            position: "front",
            stroke: {
              color: this.layoutConfig("colors.gray.gray-300"),
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: true,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family"),
            },
          },
        },
        yaxis: {
          min: 0,
          show: false,
          labels: {
            show: false,
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family"),
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family"),
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        }
      }
    },
    xLabels() {
      if (this.exportStartDate && this.exportEndDate && this.campaignStatistics.length) {

        let labels = new Array(this.campaignStatistics[0].data.length).fill('');
        for (let i = 0; i < this.campaignStatistics[0].data.length; i++) {
          labels[i] = moment(this.selectedCampaign.start_date_millis).add(i, "days").format("DD/MM/YYYY");
        }

        return labels;
      }
    },
    exportableStatistics() {
      if (!this.campaignStatistics.length) return [];

      let startIndex = 0;
      let endIndex = this.campaignStatistics[0].data.length - 1;

      if(this.selectedCampaign) {
        let startMoment = moment(this.selectedCampaign.start_date_millis);
        let endMoment = moment(this.selectedCampaign.end_date_millis);

        let exportStartMoment = startMoment;
        if (this.exportStartDate) {
          exportStartMoment = moment(this.exportStartDate);
          startIndex = exportStartMoment.diff(startMoment, 'days');
        }

        let exportEndMoment = endMoment;
        if (this.exportEndDate) {
          exportEndMoment = moment(this.exportEndDate);
          endIndex = exportEndMoment.diff(startMoment, 'days');
        }

        let exportableStatistics = [];
        for (let i = startIndex; i <= endIndex; i++) {
          exportableStatistics.push(
              {
                date: moment(startMoment).add(i, 'days').format('DD/MM/YYYY'),
                count: this.campaignStatistics[0].data[i]
              }
          )
        }

        return exportableStatistics;
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    initialize() {
      this.$store.dispatch(FETCH_CAMPAIGNS)
          .then(() => {
            this.dataLoaded = true;
          })
    },
    generateCampaignId(campaignName) {
      // return campaignName.toLowerCase().replace(' ', '');
      return short().new();
    },
    generateLink() {
      if (!this.editedItem.id) this.editedItem.id = this.generateCampaignId();
      this.generatingLink = true;
      this.$store.dispatch(GENERATE_LINK, this.editedItem)
          .then((response) => {
            this.editedItem.dynamic_link = response.shortLink;
            this.generatingLink = false;
          })
          .catch(err => {
            this.generatingLink = false;
            console.log(err);
          })
    },
    editItem(item) {
      this.editedIndex = this.campaigns.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dates = [moment(this.editedItem.start_date_millis).format('YYYY-MM-DD'), moment(this.editedItem.end_date_millis).format('YYYY-MM-DD')]
      this.dialog = true
    },
    save() {
      this.editedItem.start_date_millis = moment(this.dates[0]).valueOf();
      this.editedItem.end_date_millis = moment(this.dates[1]).valueOf();

      let action = null;
      if (this.editedIndex > -1) {
        action = this.$store.dispatch(EDIT_CAMPAIGN, this.editedItem);
      } else {
        action = this.$store.dispatch(ADD_CAMPAIGN, this.editedItem);
      }

      this.dataLoaded = false;
      action
          .then(() => {
            this.dataLoaded = true;
            this.close();
          })
          .catch(err => {
            console.log(err);
            this.dataLoaded = true;
            this.close();
          })
    },
    close() {
      this.dialog = false
      this.generatingLink = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.dates = []
      })
    },
    showStatistics(item) {
      this.selectedCampaign = item;
      this.statistics = true;
      this.statisticsLoading = true;

      this.exportStartDate = moment(item.start_date_millis).toDate();
      this.exportEndDate = moment(item.end_date_millis).toDate();

      this.calendarMin = moment(item.start_date_millis).toDate();
      this.calendarMax = moment(item.end_date_millis).toDate();


      this.$store.dispatch(FETCH_CAMPAIGN_STATISTICS, this.selectedCampaign.id)
          .then(() => {
            this.statisticsLoading = false;
          })
          .catch(err => console.log(err));
    },
    closeStatistics() {
      this.statistics = false;
      this.statisticsLoading = false;

      this.$nextTick(() => {
        this.selectedCampaign = Object.assign({}, {name: ''});
        this.exportStartDate = null;
        this.exportEndDate = null;
      })
    },
    formatDate(millis) {
      return moment(millis).format("DD/MM/YYYY");
    }
  },
  mounted() {
    this.initialize();
  }
}
</script>

<style lang="sass" scoped>
</style>