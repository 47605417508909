import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import users from "./user.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import file from "./file.module";
import statistics from "./statistics.module";
import orders from "./orders.module";
import campaigns from "./campaigns.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    users,
    file,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    statistics,
    orders,
    campaigns
  }
});
