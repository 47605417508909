import ApiService from "@/core/services/api.service";
import {SET_POLICY_TOTAL, SET_USER_VARIATION} from "@/core/services/store/statistics.module";

export const FETCH_ORDERS = "fetchOrders";
export const SET_ORDERS = "fetchOrders";

const state = {
    orders: []
}

const getters = {
    orders(state) {
        return state.orders;
    }
}

const actions = {
    [FETCH_ORDERS](context) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get("/api/orders")
                .then(({ data }) => {
                    context.commit(SET_ORDERS, data);
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    }
}

const mutations = {
    [SET_ORDERS](state, data) {
        state.orders = data;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
