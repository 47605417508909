import ApiService from "@/core/services/api.service";

// action types
export const UPLOAD_FILE = "uploadFile";

const state = {
};

const getters = {
};

const actions = {
    [UPLOAD_FILE](context, {file, path}) {
        ApiService.setHeader();
        const formData = new FormData();
        formData.append("file", file, file.filename);
        formData.append("path", path);
        return ApiService.post('api/files/upload', formData);
    }
};

const mutations = {

};

export default {
    state,
    actions,
    mutations,
    getters
};
