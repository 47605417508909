<template>
  <b-card class="p-0 m-0 elevation-1" body-class="d-flex flex-column">
    <b-card-title class="px-9 pt-9">{{ title }}</b-card-title>
    <b-card-text class="p-4">
      <b-row class="px-9">
        <b-col style="min-width: 75px">
          <b-row>
            <span class="font-weight-bold title mt-2">{{ label1 }}</span>
          </b-row>
          <b-row>
            <span class="font-weight-bolder font-size-h2 text-dark">{{ value1 }}</span>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <span class="font-weight-bold title mt-2">Variatie <small class="px-1"> *vs luna anterioara</small></span>
          </b-row>
          <b-row>
            <span class="font-weight-bolder font-size-h2" :class="value2 > 0 ? 'text-success' : 'text-danger'">{{
                value2 > 0 ? '+' + value2 : value2
              }}</span>
          </b-row>
        </b-col>
      </b-row>
    </b-card-text>
    <apexchart
        class="card-rounded-bottom mt-5 mt-auto "
        :options="chartOptions"
        :series="series"
        type="area"
    ></apexchart>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "VariationStats",
  props: ['label1', 'label2', 'value1', 'value2', 'title', 'series'],
  data() {
    return {
      chartOptions: {},
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    xLabels() {
      let labels = new Array(24).fill('');
      let now = new Date();
      let month = now.getMonth() + 1;
      let year = now.getFullYear();

      for (let i = 23; i > -1; i--) {
        labels[i] = String(month).padStart(2, '0') + '.' + year;
        month--;
        if (month <= 0) {
          month = 12;
          year--;
        }
      }

      return labels;
    }
  },
  mounted() {
    // reference; kt_stats_widget_7_chart
    let that = this;
    this.chartOptions = {
      chart: {
        type: "area",
        height: 170,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.layoutConfig("colors.theme.base.info")],
      },
      xaxis: {
        // categories: ["23 luni","22 luni","21 luni","20 luni","19 luni","18 luni","17 luni","16 luni","15 luni","14 luni","13 luni", "12 luni", "11 luni","10 luni","9 luni","8 luni","7 luni","6 luni","5 luni","4 luni","3 luni","2 luni","o luna", "luna aceasta"],
        categories: this.xLabels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family"),
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family"),
          },
        },
      },
      yaxis: {
        min: 0,
        show: false,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family"),
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family"),
        },
        y: {
          formatter: function (val) {
            return val + ' ' + that.title;
          },
        },
      },
      colors: [this.layoutConfig("colors.theme.light.info")],
      markers: {
        colors: [this.layoutConfig("colors.theme.base.info")],
        strokeColor: [this.layoutConfig("colors.theme.base.info")],
        strokeWidth: 3,
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
    };
  },
};
</script>
<style scoped>
.card-body {
  padding: 0px;
  margin: 0px;
}
</style>
