import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import FirebaseService from "@/core/services/firebase.service";
import VueSwal from "vue-swal";
import "leaflet/dist/leaflet.css";
// import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import { firestorePlugin } from "vuefire";
import Multiselect from "vue-multiselect";
import JsonCSV from "vue-json-csv";

Vue.use(firestorePlugin);
Vue.use(VueSwal);
Vue.component("multiselect", Multiselect);
Vue.component("downloadCsv", JsonCSV);
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();

// Firebase service init
FirebaseService.init();
let app;
FirebaseService.getAuth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

//fixes missing leaflet marker icons
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// new Vue({
//     router,
//     store,
//     i18n,
//     vuetify,
//     render: h => h(App)
// }).$mount("#app");
