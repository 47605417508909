<template>
  <div>
    <v-card class="elevation-1 my-4">
      <v-card-title>Filtrare</v-card-title>
      <v-card-text>
        <v-row>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label for="dp_start">De la</label>
            <b-form-datepicker
                id="dp_start"
                v-model="filters.startDate"
                class="mb-2"
                locale="ro-RO"
                reset-button
                label-no-date-selected="alegeti...">
            </b-form-datepicker>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label for="dp_end">Pana la</label>
            <b-form-datepicker
                id="dp_end"
                v-model="filters.endDate"
                class="mb-2"
                locale="ro-RO"
                reset-button
                label-no-date-selected="alegeti...">
            </b-form-datepicker>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label for="select_status">Status</label>
            <b-select
                id="select_status"
                v-model="filters.status"
                :options="statusOptions"
                class="mb-2">
            </b-select>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label for="select_type">Tip</label>
            <b-select
                id="select_type"
                v-model="filters.type"
                :options="typeOptions"
                class="mb-2">
            </b-select>
          </v-col>
          <v-col lg="4" md="6" sm="12" cols="12">
            <label for="select_type">Asigurator</label>
            <b-select
                id="select_type"
                v-model="filters.provider"
                :options="providerOptions"
                class="mb-2">
            </b-select>
          </v-col>
          <v-col lg="2" md="3" sm="6" cols="12">
            <label></label>
            <div>
              <v-btn class="my-2 w-100" outlined color="gray" @click="clearFilters">Elimina filtrele</v-btn>
            </div>

          </v-col>
          <v-col lg="2" md="3" sm="6" cols="12">
            <label></label>
            <div>
              <download-csv
                  :data="exportableItems"
                  :fields="[
                'orderId',
                'owner',
                'cnp',
                'type',
                'provider',
                'registrationNumber',
                'vin',
                'amount',
                'issueDate'
              ]">
                <v-btn class="my-2 w-100" color="warning" :disabled="!exportableItems.length"> Export CSV
                  {{ exportableItems.length ? `(${exportableItems.length})` : '' }}
                </v-btn>
              </download-csv>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-alert dark color="error" v-if="error" class="text-center py-1">
      A intervenit o eroare la obtinerea comenzilor.
      <v-btn icon @click="initialize"><v-icon>mdi-refresh</v-icon></v-btn>
    </v-alert>
    <v-data-table
        :headers="headers"
        :items="filteredOrders"
        :loading="!dataLoaded"
        loading-text="Se incarca..."
        sort-by="issueDate"
        sort-desc
        class="elevation-1"
        show-select
        v-model="exportableItems"
        item-key="key"
        @toggle-select-all="selectAll"
        :footer-props="{ itemsPerPageText: 'Linii per pagina ' }"
    >
      <template v-slot:item.logo="{ item }">
        <img
            :src="item.logo"
            class="h-50 align-self-center"
            alt=""
            onerror="this.onerror=null; this.src='media/svg/icons/Home/Building.svg'"
        />
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-col>
            <v-toolbar-title style="min-width: 65px">Comenzi</v-toolbar-title>
          </v-col>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
              class="mx-2"
              v-model="search.orderId"
              append-icon="mdi-magnify"
              label="Cauta ID comanda"
              single-line
              hide-details
          ></v-text-field>
          <v-text-field
              class="mx-2"
              v-model="search.owner"
              append-icon="mdi-magnify"
              label="Cauta beneficiar"
              single-line
              hide-details
          ></v-text-field>
          <v-text-field
              class="mx-2"
              v-model="search.vin"
              append-icon="mdi-magnify"
              label="Cauta VIN"
              single-line
              hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }"></template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} din {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset</v-btn>
      </template>
      <template v-slot:item.amount="{ item }">
        {{ item.amount ? item.amount + ' RON' : '-' }}
      </template>
      <template v-slot:item.issueDate="{ item }">
        {{ item.issueDate ? formatDate(item.issueDate) : '-' }}
      </template>
      <template v-slot:item.status="{ item }">
        <b-badge pill v-if="item.status === 'emisa'" variant="success">Emisa</b-badge>
        <b-badge pill v-if="item.status === 'eroare'" variant="danger">Eroare</b-badge>
      </template>
      <template v-slot:item.provider="{ item }">
        <div class="text-center">
          <img :src="`media/providers/img_logo_${item.provider}.png`" style="max-height: 50px; max-width: 100px">
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  FETCH_CITIES,
  FETCH_COUNTIES,
} from "@/core/services/store/user.module";
import {mapGetters} from "vuex";
import {FETCH_ORDERS} from "@/core/services/store/orders.module";
import moment from "moment";

export default {
  name: "Orders",
  data() {
    return {
      dialog: false,
      dataLoaded: false,
      search: {
        owner: '',
        vin: '',
        orderId: ''
      },
      headers: [
        {text: "Asigurator", value: "provider"},
        {text: "ID Comanda", value: "orderId"},
        {text: "Beneficiar", value: "owner"},
        {text: "CNP", value: "cnp"},
        {text: "Tip", value: "type"},
        {text: "Nr. Inmatriculare", value: "registrationNumber"},
        {text: "VIN", value: "vin"},
        {text: "Valoare", value: "amount"},
        {text: "Data", value: "issueDate"},
        {text: "Status", value: "status"}
      ],
      filters: {
        startDate: null,
        endDate: null,
        status: "Toate",
        type: "Toate",
        provider: "Toti",
      },
      statusOptions: ["Toate", {text: 'Emise', value: 'emisa'}, {text: 'Erori', value: 'eroare'}],
      exportableItems: [],
      error: false
    };
  },
  computed: {
    ...mapGetters(["cities", "counties", "orders"]),
    filteredOrders() {
      let filteredOrders = [];
      for (let order of this.orders) {
        let valid = true;

        if (this.filters.type !== 'Toate' && order.type !== this.filters.type) valid = false;
        if (this.filters.status !== 'Toate' && order.status !== this.filters.status) valid = false;
        if (this.filters.provider !== 'Toti' && order.provider !== this.filters.provider) valid = false;
        if (this.filters.startDate) {
          if (!order.issueDate || moment(this.filters.startDate).isAfter(moment(order.issueDate))) valid = false;
        }
        if (this.filters.endDate) {
          if (!order.issueDate || moment(this.filters.endDate).isBefore(moment(order.issueDate))) valid = false;
        }

        if (this.search.orderId && (!order.orderId || !order.orderId.toLowerCase().includes(this.search.orderId.toLowerCase()))) valid = false;
        if (this.search.vin && (!order.vin || !order.vin.toLowerCase().includes(this.search.vin.toLowerCase()))) valid = false;
        if (this.search.owner && (!order.owner || !order.owner.toLowerCase().includes(this.search.owner.toLowerCase()))) valid = false;

        if (valid) filteredOrders.push(order);
      }

      this.exportableItems = [];
      return filteredOrders;
    },
    typeOptions() {
      let typeOptions = ['Toate'];
      for (let order of this.orders) {
        if (!typeOptions.includes(order.type))
          typeOptions.push(order.type);
      }
      return typeOptions;
    },
    providerOptions() {
      let providerOptions = ['Toti'];
      for (let order of this.orders) {
        if (!providerOptions.includes(order.provider))
          providerOptions.push(order.provider);
      }
      return providerOptions;
    },
    countyFilter() {
      return this.filters.county;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    countyFilter() {
      if (this.filters.county) {
        this.filters.city = null;
        this.$store.dispatch(FETCH_CITIES, this.filters.county.code);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.dataLoaded = false;
      this.error = false;

      Promise.all([
        this.$store.dispatch(FETCH_COUNTIES),
        this.$store.dispatch(FETCH_ORDERS),
      ]).then(() => {
        this.dataLoaded = true;
      }).catch(err => {
        this.dataLoaded = true;
        this.error = true;
      });
    },
    formatDate(timestamp) {
      return moment(timestamp).format('DD/MM/YYYY HH:mm');
    },
    selectAll(event) {
      console.log(event.value);
      if (event.value) {
        this.exportableItems = this.filteredOrders;
      } else {
        this.exportableItems = [];
      }
    },
    clearFilters() {
      this.filters = {
        startDate: null,
        endDate: null,
        status: "Toate",
        type: "Toate",
        provider: "Toti"
      }
    },
    validate() {
      this.$refs.form.validate();
    }
    ,
  },
};
</script>
