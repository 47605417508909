import ApiService from "@/core/services/api.service";
import {SET_COUNTIES} from "@/core/services/store/user.module";

export const FETCH_USER_VARIATION = "fetchUserVariation";
export const FETCH_QUOTATION_VARIATION = "fetchQuotationVariation";
export const FETCH_RCA_QUOTATION_VARIATION = "fetchRcaQuotationVariation";
export const FETCH_TRAVEL_QUOTATION_VARIATION = "fetchTravelQuotationVariation";
export const FETCH_POLICY_TOTAL = "fetchPolicyTotal";
export const FETCH_CAMPAIGN_STATISTICS = "fetchCampaignStatistics";

export const SET_USER_VARIATION = "setUserVariation";
export const SET_QUOTATION_VARIATION = "setQuotationVariation";
export const SET_RCA_QUOTATION_VARIATION = "setRcaQuotationVariation";
export const SET_TRAVEL_QUOTATION_VARIATION = "setTravelQuotationVariation";
export const SET_POLICY_TOTAL = "setPolicyTotal";
export const SET_CAMPAIGN_STATISTICS = "setCampaignStatistics";

const state = {
    stats: {
        totals: {
            orders: {
                total: 0,
                revenue: 0
            },
            rca: {
                total: 0,
                revenue: 0
            },
            travel: {
                total: 0,
                revenue: 0
            },
        },
        variation: {
            users: {
                total: 0,
                thisMonth: +0,
                series: [
                    {
                        name: "Total",
                        data: [],
                    },
                ]
            },
            quotations: {
                rca: {
                    total: 0,
                    thisMonth: +0,
                    series: [
                        {
                            name: "Total",
                            data: [],
                        },
                    ]
                },
                travel: {
                    total: 0,
                    thisMonth: +0,
                    series: [
                        {
                            name: "Total",
                            data: [],
                        },
                    ]
                }
            }
        },
    },
    campaignStatistics: []
};

const getters = {
    stats(state) {
        return state.stats;
    },
    campaignStatistics(state) {
        return state.campaignStatistics;
    }
}

const actions = {
    [FETCH_USER_VARIATION](context) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get("/api/statistics/variation/users")
                .then(({ data }) => {
                    context.commit(SET_USER_VARIATION, data);
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    },
    [FETCH_QUOTATION_VARIATION](context, type) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get("/api/statistics/variation/quotations/"+type)
                .then(({ data }) => {
                    context.commit(SET_QUOTATION_VARIATION, {type: type, data: data});
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    },
    [FETCH_POLICY_TOTAL](context, type) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get("/api/statistics/total/policies/" + type)
                .then(({ data }) => {
                    context.commit(SET_POLICY_TOTAL, {type, data});
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    },
    [FETCH_CAMPAIGN_STATISTICS](context, campaignId) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/statistics/variation/campaigns/${campaignId}`)
                .then(({ data }) => {
                    context.commit(SET_CAMPAIGN_STATISTICS, data);
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
    }
}

const mutations = {
    [SET_USER_VARIATION](state, data) {
        state.stats.variation.users.total = data.total;
        state.stats.variation.users.thisMonth = data.thisMonth;
        state.stats.variation.users.series = [
            {
                name: "Total",
                data: data.totalEachMonth,
            },
        ]
    },
    [SET_QUOTATION_VARIATION](state, input) {
        state.stats.variation.quotations[input['type']].total = input.data.total;
        state.stats.variation.quotations[input['type']].thisMonth = input.data.thisMonth;
        state.stats.variation.quotations[input['type']].series = [
            {
                name: "Total",
                data: input.data.totalEachMonth,
            },
        ]
    },
    [SET_POLICY_TOTAL](state, input) {
        state.stats.totals[input.type] = input.data;
        state.stats.totals['orders'].policies = state.stats.totals['rca'].policies + state.stats.totals['travel'].policies;
        state.stats.totals['orders'].revenue = state.stats.totals['rca'].revenue + state.stats.totals['travel'].revenue;
    },
    [SET_CAMPAIGN_STATISTICS](state, data) {
        state.campaignStatistics = data;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};