import ApiService from "@/core/services/api.service";
import FirebaseService from "@/core/services/firebase.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_PASSWORD = "updateUser";
export const FETCH_USER = "fetchUser";
export const INVITE_ADMIN = "inviteAdmin";
export const REGISTER = "register";
export const FORGOT_PASSWORD = "forgotPassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_TOKEN = "setToken";
export const SET_USER = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_CURRENT_CITY = "setCurrentCity";

const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isAdmin(state) {
    return state.user.type === "admin";
  },
  isSuperadmin(state) {
    return state.user.type === "superadmin";
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      FirebaseService.login(credentials.email, credentials.password)
        .then(token => {
          context.commit(SET_TOKEN, token);
          context
            .dispatch(FETCH_USER, token)
            .then(data => {
              resolve(data);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [FORGOT_PASSWORD](context, email) {
    return FirebaseService.forgotPassword(email);
  },
  [LOGOUT](context) {
    FirebaseService.logout().then(() => {
      context.commit(PURGE_AUTH);
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken() && FirebaseService.isAuthenticated()) {
      return true;
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [FETCH_USER](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("api/users/me")
        .then(({ data }) => {
          context.commit(SET_USER, data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [INVITE_ADMIN](context, admin) {
    ApiService.setHeader();
    return ApiService.post("api/users/invite", admin);
  },
  [REGISTER](context, admin) {
    ApiService.setHeader();
    return ApiService.post("api/users/register", admin);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    // if(!state.errors)
    //     state.errors = [];
    // console.log(state.errors);
    // state.errors.push(error);
    console.log(error);
  },
  [SET_TOKEN](state, token) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(token);
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_CURRENT_CITY](state, cityKey) {
    state.user.cityKey = cityKey;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
