import Firebase from "firebase";

let firebaseConfig = require("../../../mysafety_admin_front.json");

const FirebaseService = {
  app: null,
  init() {
    if (!Firebase.apps.length)
      this.app = Firebase.initializeApp(firebaseConfig);
  },

  login(email, password) {
    return new Promise((resolve, reject) => {
      Firebase.auth()
        .setPersistence(Firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          Firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then(async () => {
              return await this.currentUser()
                .getIdToken(true)
                .then(idToken => {
                  resolve(idToken);
                })
                .catch(err => reject(err));
            })
            .catch(err => reject(err));
        })
        .catch(err => reject(err));
    });
  },
  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      Firebase.auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve("Email trimis.");
        })
        .catch(err => reject(err));
    });
  },
  logout() {
    return Firebase.auth().signOut();
  },

  currentUser() {
    return Firebase.auth().currentUser;
  },

  isAuthenticated() {
    return this.currentUser() !== null;
  },

  getAuth() {
    return Firebase.auth();
  },
};

export default FirebaseService;
