<template>
  <div class="topbar-item">
    <div
      class="
        btn btn-icon
        w-auto
        btn-clean
        d-flex
        align-items-center
        btn-lg
        px-2
      "
      id="kt_quick_user_toggle"
      @click="open = !open"
    >
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        Buna,
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ currentUser.first_name }} {{ currentUser.last_name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="photo" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{
            currentUser.first_name
              ? currentUser.first_name.charAt(0).toUpperCase()
              : ""
          }}
          {{
            currentUser.first_name
              ? currentUser.last_name.charAt(0).toUpperCase()
              : ""
          }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10 "
      :class="open ? 'offcanvas-on' : ''"
    >
      <!--begin::Header-->
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
          @click="open = false"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="photo" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to=""
              class="
                font-weight-bold font-size-h5
                text-dark-75 text-hover-primary
              "
            >
              {{ getFullName }}
            </router-link>
            <div class="text-muted mt-1">{{ currentUser.type }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Logout
            </button>
          </div>
        </div>
        <!--end::Header-->
<!--        <div class="separator separator-dashed mt-8 mb-5"></div>-->
        <!--begin::Nav-->
<!--        <div class="navi navi-spacer-x-0 p-0">-->
<!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--          <router-link-->
<!--            to=""-->
<!--            @click.native="closeOffcanvas"-->
<!--            href="#"-->
<!--            class="navi-item"-->
<!--          >-->
<!--            <div class="navi-link">-->
<!--              <div class="symbol symbol-40 bg-light mr-3">-->
<!--                <div class="symbol-label">-->
<!--                  <span class="svg-icon svg-icon-md svg-icon-success">-->
<!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
<!--                    <inline-svg-->
<!--                      src="media/svg/icons/General/Notification2.svg"-->
<!--                    />-->
<!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="navi-text" @click="sendResetEmail">-->
<!--                <div class="font-weight-bold">Resetare parola</div>-->
<!--                <div class="text-muted">-->
<!--                  Trimite email de resetare a parolei.-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </router-link>-->
<!--          &lt;!&ndash;end:Item&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Nav&ndash;&gt;-->
<!--        <div class="separator separator-dashed my-7"></div>-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { FORGOT_PASSWORD, LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [],
      open: false
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    sendResetEmail() {
      this.$store
        .dispatch(FORGOT_PASSWORD, this.currentUser.email)
        .then(() => {
          this.$swal(
            "Succes!",
            "Verificati email-ul pentru a continua.",
            "success"
          );
        })
        .catch(() => {
          this.$swal("Eroare!", "Ceva nu a mers.", "error");
        });
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),

    getFullName() {
      return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
    },
    photo() {
      return this.currentUser.photo
        ? this.currentUser.photo
        : "media/blank.png";
    }
  }
};
</script>
