<template>
  <!--begin::Stats Widget 11-->
  <b-card class="elevation-1">
    <b-card-title class="p-0">{{ title }}</b-card-title>
    <!--begin::Body-->
    <b-card-text class="p-4">
      <b-row>
        <b-col class="py-4" style="min-width: 150px">
          <b-row>
            <span class="font-weight-bold title mt-2">{{ label1 }}</span>
          </b-row>
          <b-row>
            <span class="font-weight-bolder font-size-h2 text-dark">{{ value1 ? value1.toFixed(2) : 0 }}</span>
          </b-row>
        </b-col>
        <b-col class="py-4" style="min-width: 150px">
          <b-row>
            <span class="font-weight-bold title mt-2">{{ label2 }}</span>
          </b-row>
          <b-row>
            <span class="font-weight-bolder font-size-h2 text-info">{{ value2 ? value2.toFixed(2) : 0 }} Lei</span>
          </b-row>
        </b-col>
      </b-row>
    </b-card-text>
    <!--end::Body-->
  </b-card>
  <!--end::Stats Widget 11-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TotalStats",
  data() {
    return {
    };
  },
  props: ['label1', 'label2', 'value1', 'value2', 'title'],
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
  },
};
</script>
